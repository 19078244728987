import Pfsz from "../page/pfsz.vue"

const routes = [
    { path: '/',redirect:'/index' },

    { path: '/index', component: ()=>import('../page/index.vue') },
    // { path: '/index', component: Pfsz },
    { path: '/windows', component: ()=>import('../page/windows.vue') },
	{ path: '/wa', component: ()=>import('../page/wa.vue') },
	{ path: '/wb', component: ()=>import('../page/wb.vue') },
	{ path: '/pfsz', component: Pfsz },
	{ path: '/pfsz_add', component: ()=>import('../page/pfsz_add.vue') },
    { path: '/macmin', component: ()=>import('../components/MinimalAnimation.vue') }
  ]

export default routes