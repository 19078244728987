<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <MinimalAnimation /> -->
    <!-- <MinimalAnimation /> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import MinimalAnimation from './components/MinimalAnimation.vue'
// import MySwiper from "./components/MySwiper.vue"
export default {
  name: 'App',
  // components: {
  //   // HelloWorld,
  //   MinimalAnimation,
  //   // MySwiper
  // }
}
</script>

<style>
#app {
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;

}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 18px;
  
}
</style>
