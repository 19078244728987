import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import axios from "axios";
import VueAxios from 'vue-axios'

Vue.config.productionTip = false
import VueRouter from 'vue-router'
import routes from "./router/index.js";
import setDirectives from "./utils/dragAndFull.js";
setDirectives(Vue)
Vue.use(VueRouter)
Vue.use(VueAxios,axios);
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
// Vue.prototype.$ajax = axios;
// Vue.prototype.URL_HOST = "https://srd.rongqingkj.cn/"; //（服务器环境）
Vue.prototype.URL_HOST = "/api"; //全局域名映射（开发环境，解决跨域）

Vue.use(ElementUI)
const router = new VueRouter({
  routes,
  mode:'history'//修改路由模式为'history'模
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
